import { configureStore } from "@reduxjs/toolkit";
import { logger } from "redux-logger";
import rootReducer from "./ReduxIndex";
let midarray = [];
if (process.env.REACT_APP_ENVIRONMENT === "beta") {
  midarray.push(logger);
}
const store = configureStore({
  reducer: rootReducer,
  middleware: () => midarray,
});
export default store;
