const initialStateVal = {
  tableData: [],
  keyWiseData: {},
  rejectMessage: "",
  dropdownOptions: {
    community: [],
    country: [],
  },
  dropdownValues: {
    community: [],
    country: [],
  },
  childVideoDetails: {},
  reasonList: [],
};
const ChildGreetingVideoReviewReducer = (state = initialStateVal, action) => {
  switch (action.type) {
    case "videoGreetingTableData":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case "greetingVideoFilter":
      return {
        ...state,
        [action.payload.tabName]: {
          ...state[action.payload.tabName],
          [action.payload.propName]: action.payload.propValue,
        },
      };
    case "detailsUpdaterCGV":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case "cgvOnClear":
      return {
        ...state,
        childVideoDetails: {},
        rejectMessage: "",
      };

    default:
      return state;
  }
};
export default ChildGreetingVideoReviewReducer;
