const initialStateVal = {
  tableData: [],
  keyWiseData: {},
  rmDropdownOptions: {
    community: [],
    country: [],
  },
  rmDropdownValues: {
    community: [],
    country: [],
  },
  rmDetails: {},
  rmLetters: {},
  rmTranslationData: [],
  historyDetails: [],
  reasonList: [],
  isMsgButtonDisabled: false,
  isTypedText: false,
  skipTranslation: false,
  initialTranslation: "",
};
const ReplyMessageReducer = (state = initialStateVal, action) => {
  switch (action.type) {
    case "replyMessageTableData":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case "rmTransalation":
      let currentValue = [...state["rmTranslationData"]];
      currentValue[action.payload.propIndex]["translation"] =
        action.payload.propValue;

      return {
        ...state,
        [action.payload.name]: currentValue,
      };
    case "replyMessageFilter":
      return {
        ...state,
        [action.payload.tabName]: {
          ...state[action.payload.tabName],
          [action.payload.propName]: action.payload.propValue,
        },
      };
    case "rmOnClear":
      return {
        ...state,
        rmTranslationData: [],
        rmDetails: {},
        rmLetters: {},
        isMsgButtonDisabled: false,
        isTypedText: false,
        skipTranslation: false,
        initialTranslation: "",
      };
    default:
      return state;
  }
};
export default ReplyMessageReducer;
