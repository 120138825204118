const initialStateVal = {
  tableData: [],
  keyWiseData: {},
  ilDropdownOptions: {
    community: [],
    country: [],
  },
  ilDropdownValues: {
    community: [],
    country: [],
  },
  ilDetails: {},
  ilLetters: {},
  translationData: [],
  historyDetails: [],
  reasonList: [],
  isMsgButtonDisabled: false,
};
const ChildIlReducer = (state = initialStateVal, action) => {
  switch (action.type) {
    case "childIlData":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case "ilTransalation":
      let currentValue = [...state["translationData"]];
      currentValue[action.payload.propIndex]["translation"] =
        action.payload.propValue;

      return {
        ...state,
        [action.payload.name]: currentValue,
      };
    case "updateKeyWiseChildIl":
      return {
        ...state,
        [action.payload.tabName]: {
          ...state[action.payload.tabName],
          [action.payload.propName]: action.payload.propValue,
        },
      };
    case "ilOnClear":
      return {
        ...state,
        translationData: [],
        ilDetails: {},
        ilLetters: {},
      };

    default:
      return state;
  }
};
export default ChildIlReducer;
