const initialStateVal = {
  tableData: [],
  keyWiseData: {},
  ccDropdownOptions: {
    community: [],
    country: [],
  },
  ccDropdownValues: {
    community: [],
    country: [],
  },
  ccDetails: {},
  ccLetters: {},
  ccTranslationData: [],
  historyDetails: [],
  skipTranslation: false,
  initialTranslation: "",
  reasonList: [],
  isPhotoButtonDisabled: false,
};
const CRMApprovedRejectedReducer = (state = initialStateVal, action) => {
  switch (action.type) {
    case "ccApprovedRejectedTableData":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case "ccApprovedRejectedTransalation":
      let currentValue = [...state["ccTranslationData"]];
      currentValue[action.payload.propIndex]["translation"] =
        action.payload.propValue;

      return {
        ...state,
        [action.payload.name]: currentValue,
      };
    case "ccApprovedRejectedFilter":
      return {
        ...state,
        [action.payload.tabName]: {
          ...state[action.payload.tabName],
          [action.payload.propName]: action.payload.propValue,
        },
      };
    case "ccApprovedRejectedOnClear":
      return {
        ...state,
        ccTranslationData: [],
        ccDetails: {},
        ccLetters: {},
        isPhotoButtonDisabled: false,
      };
    default:
      return state;
  }
};
export default CRMApprovedRejectedReducer;
