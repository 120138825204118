const initialStateVal = {
  tableData: [],
  keyWiseData: {},
  rlDropdownOptions: {
    community: [],
    country: [],
  },
  rlDropdownValues: {
    community: [],
    country: [],
  },
  rlDetails: {},
  rlLetters: {},
  rlTranslationData: [],
  historyDetails: [],
  reasonList: [],
  isMsgButtonDisabled: false,
  isTypedText: false,
};
const ReplyLetterReducer = (state = initialStateVal, action) => {
  switch (action.type) {
    case "replyLetterTableData":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case "rlTransalation":
      let currentValue = [...state["rlTranslationData"]];
      currentValue[action.payload.propIndex]["translation"] =
        action.payload.propValue;

      return {
        ...state,
        [action.payload.name]: currentValue,
      };
    case "replyLetterFilter":
      return {
        ...state,
        [action.payload.tabName]: {
          ...state[action.payload.tabName],
          [action.payload.propName]: action.payload.propValue,
        },
      };
    case "rlOnClear":
      return {
        ...state,
        rlTranslationData: [],
        rlDetails: {},
        rlLetters: {},
      };
    default:
      return state;
  }
};
export default ReplyLetterReducer;
