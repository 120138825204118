const initialStateVal = {
  dldropdownOptions: {
    community: [],
  },
  dldropdownValues: {
    community: {},
  },
};
const SponsorIntroCardReducer = (state = initialStateVal, action) => {
  switch (action.type) {
    case "CoummunitySetter":
      return {
        ...state,
        [action.payload.tabName]: {
          ...state[action.payload.tabName],
          [action.payload.propName]: action.payload.propValue,
        },
      };

    default:
      return state;
  }
};
export default SponsorIntroCardReducer;
