const initialStateVal = {
  tableData: [],
  keyWiseData: {},
  dropdownOptions: {
    community: [],
    country: [],
  },
  dropdownValues: {
    community: [],
    country: [],
  },
  childExceptionVideoDetails: {},
  reasonList: [],
};
const ExceptionVideoMessageReviewReducer = (
  state = initialStateVal,
  action
) => {
  switch (action.type) {
    case "ExceptionVideoMsgData":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case "exceptionVidMsgFilter":
      return {
        ...state,
        [action.payload.tabName]: {
          ...state[action.payload.tabName],
          [action.payload.propName]: action.payload.propValue,
        },
      };
    case "evmOnClear":
      return {
        ...state,
        childExceptionVideoDetails: {},
      };

    default:
      return state;
  }
};
export default ExceptionVideoMessageReviewReducer;
